































import {FaqInterface} from '@/shared/interfaces/store/homepage/faq.interface';
import {HomepageStore} from '@/store/modules/homepage';
import {Component, Vue} from 'vue-property-decorator';

@Component
export default class HomeFAQ extends Vue {

  get faq(): FaqInterface {
    return HomepageStore.faq;
  }

}
